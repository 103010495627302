import { Modal } from "@components/Modal/Modal";
import {
  ModalsDispatchContext,
  ModalsStateContext,
} from "@components/Modal/ModalContext";
import { useContext } from "react";

export const modals = {
  Modal,
};

const Modals = () => {
  const openedModals = useContext(ModalsStateContext);
  const { close } = useContext(ModalsDispatchContext);

  return openedModals.map((modal, index) => {
    const { Component, props, isOpen } = modal;
    if (!props) return null;

    const onClose = () => {
      close(Component);
    };

    return (
      <Component key={index} isOpen={isOpen} onClose={onClose} {...props} />
    );
  });
};
export default Modals;
