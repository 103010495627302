import {
  ModalComponent,
  ModalProps,
  ModalsDispatchContext,
  ModalsState,
  ModalsStateContext,
} from "@components/Modal/ModalContext";
import Modals from "@components/Modal/Modals";
import { PropsWithChildren, useMemo, useState } from "react";

const disableScroll = () => {
  document.body.style.cssText = `
  position: fixed; 
  top: -${window.scrollY}px;
  overflow-y: scroll;
  width: 100%;`;
};

const ableScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.cssText = "";
  window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
};

const ModalsProvider = ({ children }: PropsWithChildren) => {
  const [openedModals, setOpenedModals] = useState<ModalsState>([]);

  const open = (Component: ModalComponent, props: ModalProps) => {
    disableScroll();
    window.history.pushState({ modal: true }, "", window.location.pathname);
    document.body.style.overflow = "hidden";
    setOpenedModals(modals => {
      const modalIndex = modals.findIndex(
        modal => modal.Component === Component,
      );
      if (modalIndex !== -1) {
        modals[modalIndex].isOpen = true;
        modals[modalIndex].props = props;
        return [...modals];
      }
      return [...modals, { Component, props, isOpen: true }];
    });
  };

  const close = (Component: ModalComponent) => {
    ableScroll();
    if (window.history.state?.modal) {
      window.history.back();
    }
    document.body.style.overflow = "auto";
    setOpenedModals(modals =>
      modals.map(modal =>
        modal.Component === Component ? { ...modal, isOpen: false } : modal,
      ),
    );
  };

  const dispatch = useMemo(() => ({ open, close }), []);

  return (
    <ModalsStateContext.Provider value={openedModals}>
      <ModalsDispatchContext.Provider value={dispatch}>
        {children}
        <Modals />
      </ModalsDispatchContext.Provider>
    </ModalsStateContext.Provider>
  );
};
export default ModalsProvider;
