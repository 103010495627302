import { ComponentType, HTMLAttributes, createContext } from "react";
export type ModalComponent = ComponentType<any>;
export type ModalProps = Record<string, any> | HTMLAttributes<HTMLDivElement>;

export type ModalsState = Array<{
  Component: ModalComponent;
  props?: ModalProps;
  isOpen: boolean;
}>;
export const ModalsStateContext = createContext<ModalsState>([]);

type ModalsDispatch = {
  open: (Component: ModalComponent, props: ModalProps) => void;
  close: (Component: ModalComponent) => void;
};
export const ModalsDispatchContext = createContext<ModalsDispatch>({
  open: () => {},
  close: () => {},
});
